import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

export const PROOF_MAXUPLOAD_SIZE=10485760

export const CHARACTER_LIMIT = 800

export const TalentType = 1; 
export const RecruiterType = 2;

export function getAge(dateString) {
    var today = new Date();
    // var birthDate = new Date(dateString).getFullYear();
    var age = today.getFullYear() - new Date(dateString).getFullYear();
    var m = today.getMonth() - new Date(dateString).getMonth();
    if (m < 0 || (m === 0 && today.getDate() < new Date(dateString).getDate())) {
        age--;
    }
    return age;
}
export const phoneRegExp = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/

export const getDatafromArray = (array, valueName) => {
    let temp = []
    array?.map((data) => (
        temp.push(data[valueName]?.trim())
    ))
    return temp.join(', ');
}
export const dateFomartfn = (date) => {
    const formattedDate = date.toLocaleDateString('en-GB', {
        day: 'numeric', month: 'numeric', year: 'numeric'
    }).replace(/ /g, '-');
    return formattedDate;
}
export const numberCheck = (event) => {
    let d = event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
    return d;
}
export function sortByProperty(property) {
    return function (a, b) {
        if (a[property] > b[property])
            return 1;
        else if (a[property] < b[property])
            return -1;

        return 0;
    }
}

export function create_UUID() {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
}

export const convertImagetoWebp = (file) => {
    return new Promise((resolve) => {
        // convert image
        let src = URL.createObjectURL(file)
        let canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d')

        let userImage = new Image();
        userImage.src = src

        function setCanvasSize(size) {
            canvas.width = (1 / size) * userImage.width;
            canvas.height = (1 / size) * userImage.height;
            ctx.drawImage(userImage, 0,0, (1 / size) * userImage.width, (1 / size) * userImage.height);

        }

        userImage.onload = function () {
            if (userImage.width > 1000 && userImage.width <= 1500) {
                setCanvasSize(1.5);
            } else if (userImage.width > 1500 && userImage.width <= 2000) {
                setCanvasSize(2);
            } else if (userImage.width > 2000 && userImage.width <= 2500) {
                setCanvasSize(3);
            }
            else if (userImage.width > 2500 && userImage.width <= 3000) {
                setCanvasSize(3.5);
            } else if (userImage.width > 3000 && userImage.width <= 4000) {
                setCanvasSize(4);
            } else if (userImage.width > 4000) {
                setCanvasSize(5);
            } else {
                canvas.width = userImage.width;
                canvas.height = userImage.height;
                ctx.drawImage(userImage, 0, 0, userImage.width, userImage.height);
            }
            let webpImage = canvas.toDataURL("image/webp", 0.5);
            return resolve(webpImage);
        }
    });
}

function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}

export function formatDate(date) {
    return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
    ].join('/');
}




export const CustomWindowScrollTo = (id) => {
    const getOffsetPostion = (id) => {

        const element = document.getElementById(id);
        const offset = 100;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element?.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;
    
    
    
        return offsetPosition
      }
    window.scrollTo({
        top: getOffsetPostion(id),
        behavior: 'smooth'
      });
}

export const high_eduData = [
    { id: 1, value: 'PhD' },
    { id: 2, value: 'Masters' },
    { id: 3, value: 'Graduation' },
    { id: 4, value: '12 th pass' },
    { id: 5, value: '10 th pass' },
    { id: 6, value:'Below matriculation'},
    { id: 7, value:'Others'}

]

export const idType_Data = [
    { id: '1', value: 'Aadhaar Card' },
    { id: '2', value: 'Driving License' },
    { id: '3', value: 'Pan Card' },
    { id: '4', value: 'Passport' },
    { id: '5', value: 'Other' },
]
export   const age_marks = [
    { value: 10, },
    { value: 15, },
    { value: 20, },
    { value: 25, },
    { value: 30, },
    { value: 35, },
    { value: 40, },
    { value: 45, },
    { value: 50, },
    { value: 55, },
    { value: 60, },
    { value: 65, },
    { value: 70, },
    { value: 75, },
    { value: 80, },
    { value: 85, },
    { value: 90, },
    { value: 95, },
  ];

 export const StyledTooltip = styled(({ className, placement='top-start',...props}) => (
    <Tooltip {...props} arrow classes={{ popper: className }}
    placement={placement}/>
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#fff",
      boxShadow: "rgba(0, 0, 0, 0.45) 0px 25px 20px -20px",
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#fff",
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
        color: "#44454D",
        fontSize: "10px",
        fontWeight: "700",
        bottom: "-10px",
        padding: "10px 20px",
        borderRadius: "10px",
        fontFamily: 'Poppins',
        maxWidth:'410px'
    },
  }))