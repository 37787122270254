import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { CustomButton } from '../../components/Button/Button'
import { AuthContext } from '../../context/AuthContext'
import { RecruiterType } from '../../utils/common'

const FreezePopup = () => {
    const history = useHistory()
    const {isProfileFreezed,user } = useContext(AuthContext)
    const [isOpen, setIsOpen] = useState(false)
    useEffect(() => {
      if(isProfileFreezed){
        // setIsPreviewd(true)
        setTimeout(() => setIsOpen(isProfileFreezed),2000)
      }else{
        setIsOpen(false)
      }
    },[isProfileFreezed])

    return (
        <Dialog open={isOpen} fullWidth={true} maxWidth={'sm'} >
            <DialogTitle id="first_alert__title" className='first_alert__title'><p className='emailErrText ' style={{ marginBottom: 10,fontSize:24 }}>Profile Freeze</p></DialogTitle>
            <DialogContent>
                <DialogContentText >
                    {user?.user_type === RecruiterType ? <div className='px-4'>
                        <p className='fontStyl text-center' >
                        Sorry, your profile is under freeze and you will not be able to edit your projects/job postings or make any changes to the profile. Please <span className='linkText' onClick={() => {
                                history.push('/about/contact-us')
                                setIsOpen(false)
                            }}>contact</span> the admin for more information.</p>
                    </div>  : <div className='px-4'>
                        <p className='fontStyl text-center' >
                            Sorry, your profile is under freeze and you will not be able to apply to new jobs or make any changes to the profile. Please  <span className='linkText' onClick={() => {
                                history.push('/about/contact-us')
                                setIsOpen(false)
                            }}>contact</span> the admin for more information.</p>
                    </div> } 
                    
                    </DialogContentText>
            </DialogContent>
            <DialogActions style={{display:'flex', justifyContent:'center',alignItems:'center',marginBottom:20}}>
                <CustomButton onClick={() => setIsOpen(false)} text='Ok'/>
            </DialogActions>
        </Dialog>

    )
}

export default FreezePopup