import React, { createContext, useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from "../assets/img/Logo.svg";

const GlobalContext = createContext();

const GlobalContextProvider = ({ children }) => {
  const [globalValue, setGlobalValue] = useState('');
//   <Link to={"/"} className="logoImage"><img src={Logo} alt="CastingBell" /></Link>

  return (
    <GlobalContext.Provider value={{ globalValue, setGlobalValue }}>
      {children}
    </GlobalContext.Provider>
  );
};

export { GlobalContext, GlobalContextProvider };
