import axios from 'axios'
import { config } from '../../assets/config/config'

const apiUrl = config.api.url


const createTalenter_LanguageAndTravel = (talent_id, nationality, ethnicity, native_city, passport_info, lived_city, countries_visited, language_skills, accent_skills) => {
    return axios.post(`${apiUrl}/v1/language-and-travel`, {
        talent_id: talent_id,
        nationality: nationality,
        ethnicity: ethnicity,
        native_city: native_city,
        lived_city: lived_city,
        passport_info: passport_info,
        countries_visited: countries_visited,
        language_skills: language_skills,
        accent_skills: accent_skills,
    })
}


const getLanguageAndTravelById = (talent_id) => {
    return axios.get(`${apiUrl}/v1/language-and-travel/${talent_id}`, {}).then((response) => {
        return response.data
    })
}
const getCityData = (anywhere = 1) => {
    return axios.get(`${apiUrl}/v1/city/get-all?anywhere=${anywhere}`).then((res) => {
        return res.data;
    })
}
const getCountry = () => {
    return axios.get(`${apiUrl}/v1/country/get-all`).then((res) => {
        return res.data;
    })
}
const getLanguage = () => {
    return axios.get(`${apiUrl}/v1/language/get-all`).then((res) => {
        return res.data;
    })
}
const getAccent = () => {
    return axios.get(`${apiUrl}/v1/accents/get-all`).then((res) => {
        return res.data;
    })
}
const Ethnicities = () => {
    return axios.get(`${apiUrl}/v1/ethnicities/get-all`).then((res) => {
        return res.data;
    })
}
const getNationality = () => {
    return axios.get(`${apiUrl}/v1/nationality/get-all`).then((res) => {
        return res.data;
    })
}

const getFilterCountry = (val) => {
    return axios.post(`${apiUrl}/v1/country/filter-country`, { country_name: val }).then(res => res)
}


const getFilterEthnicities = (val) => {
    return axios.post(`${apiUrl}/v1/ethnicities/filter-ethnicities`, { ethnicity_name: val }).then(res => res)
}

const getFilterAccent = (val) => {
    return axios.post(`${apiUrl}/v1/accents/filter-accent`, { accent_name: val }).then(res => res)
}

const getFilterLanguage = (val) => {
    return axios.post(`${apiUrl}/v1/language/filter-language`, { language_name: val }).then(res => res)
}
export const languageAndTravelService = {
    getLanguageAndTravelById,
    createTalenter_LanguageAndTravel,
    getCityData,
    getCountry,
    getLanguage,
    getAccent,
    Ethnicities,
    getFilterCountry,
    getFilterEthnicities,
    getFilterAccent,
    getFilterLanguage,
    getNationality
}