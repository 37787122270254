import axios from 'axios'
import { config } from '../../assets/config/config'


const apiUrl = config.api.url

const handleOauthUser = (user) => {
    return axios.post(`${apiUrl}/v1/talenter`, user)
}


// const updateTalenter_personalData = (user_id , user_type,first_name, last_name, current_residence, date_of_birth, mobile_number, gender, whatsapp_updates, show_to_recruiters, proof_id_number, proof_doc,proof_img,is_managed_by_manager,  mananger_email_address,  mananger_mobile_number,  send_updates_mananger_email,  send_updates_manage_mobile) => {
//   	return axios.patch(`${apiUrl}/v1/talenter/${user_id}`, {
//         user_type: user_type,
//         first_name: first_name,
//         last_name: last_name,
//         current_residence: current_residence,
//         date_of_birth: date_of_birth,
//         mobile_number: mobile_number,
//         gender: gender,
//         whatsapp_updates: whatsapp_updates,
//         show_to_recruiters: show_to_recruiters,
//         proof_id_number: proof_id_number,
//         proof_doc:proof_doc,
//         proof_img:proof_img,
//         is_managed_by_manager: is_managed_by_manager,
//         mananger_email_address: mananger_email_address,
//         mananger_mobile_number: mananger_mobile_number,
//         send_updates_mananger_email: send_updates_mananger_email,
//         send_updates_manage_mobile: send_updates_manage_mobile,

//   })
// }



const handleTalenterData = (user_id, updateData) => {
    return axios.patch(`${apiUrl}/v1/talenter/${user_id}`, updateData)
}



const getTalenterId = (user_id) => {
    return axios.get(`${apiUrl}/v1/talenter/${user_id}`, {}).then((response) => {
        return response.data
    })
}

export const TalenterService = {
    handleOauthUser,
    handleTalenterData,
    getTalenterId
}