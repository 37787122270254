import axios from 'axios'
import { config } from '../../assets/config/config'

const apiUrl = config.api.url

const GetDropdownValues =  async (gender,talentType,instagram,imdb,facebook,currentCity,maxAge,minAge,skinColor,naturalHair,eyeColor,ethnicity,preferredProject,talentRole,talentNudity,talentLoveMakeScene,talentOpenWorkCity,nationality,nativeTownOrCity,cityLived,countryVisited,passportStatus,languageSKill,accentSkill,actingEducation,theatreExperince,educationQualification,hobbies,talentSkills,talentPastWorkProject,notableAchivement,otherNotableWork, height,heightMax,
  weight_min_value,
  weight_max_value,
  bust_or_chest_min,
  bust_or_chest_max,
  waist_min,
  waist_max,
  hip_min,
  hip_max,
  height_size_type,
  weight_size_type,
  bust_or_chest_size_type,
  waist_size_type, 
  hips_size_type,) => {
    const res = await axios.get(`${apiUrl}/v1/talenter/findTalent-count?gender=${gender??''}&talentType=${talentType??''}&currentCity=${currentCity ?? ''}&minAge=${minAge ?? ''}&maxAge=${maxAge ?? ''}&instagram=${instagram ?? ''}&imdb=${imdb?? ''}&facebook=${facebook?? ''}&skinColor=${skinColor ??''}&naturalHair=${naturalHair??''}&eyeColor=${eyeColor ??''}&ethnicity=${ethnicity ??''}&preferredProject=${preferredProject ??''}&talentRole=${talentRole ?? ''}&talentNudity=${talentNudity ?? ''}&talentLoveMakeScene=${talentLoveMakeScene ?? ''}&talentOpenWorkCity=${talentOpenWorkCity ?? ''}&nationality=${nationality ?? ''}&nativeTownOrCity=${nativeTownOrCity ?? ''}&cityLived=${cityLived ?? ''}&countryVisited=${countryVisited ?? ''}&passportStatus=${passportStatus ?? ''}&languageSKill=${languageSKill ?? ''}&accentSkill=${accentSkill ?? ''}&actingEducation=${actingEducation ?? ''}&theatreExperince=${theatreExperince ?? ''}&educationQualification=${educationQualification ?? ''}&hobbies=${hobbies ?? ''}&talentSkills=${talentSkills ?? ''}&talentPastWorkProject=${talentPastWorkProject ?? ''}&notableAchivement=${notableAchivement ?? ''}&otherNotableWork=${otherNotableWork ?? ''}&minHeight=${height ?? ''}&maxHeight=${heightMax ?? ''}&heightSizeType=${height_size_type ?? ''}&minWeight=${weight_min_value ?? ''}&maxWeight=${weight_max_value ?? ''}&weightSizeType=${weight_size_type ?? ''}&minChest=${bust_or_chest_min ?? ''}&maxChest=${bust_or_chest_max ?? ''}&chestSizeType=${bust_or_chest_size_type ?? ''}&minWaist=${waist_min ?? ''}&maxWaist=${waist_max ?? ''}&waistSizeType=${waist_size_type ?? ''}&minHip=${hip_min ?? ''}&maxHip=${hip_max ?? ''}&hipSizeType=${hips_size_type ?? ''}`, )
    return res.data
  }

const FilterTalentData = async ({page = 1,limit=20,recruiterViewed,location,talentType,gender,minAge,maxAge,imdb,facebook,instagram,skinColor,hairColor,eyeColor,ethnicity,projectType,roleType,nudity,loveMaking,citiesVisited,countryVisited,passportStatus,accentSkill,languageSKill,talentOpenWorkCity,nationality,nativeCity,actingEducation,theatreExperince,heighestEducation,hobbies,skills,talentPastwork,achievmentDetail,otherNotableWork,height,heightMax,
  weight_min_value,
  weight_max_value,
  bust_or_chest_min,
  bust_or_chest_max,
  waist_min,
  waist_max,
  hip_min,
  hip_max,
  height_size_type,
  weight_size_type,
  bust_or_chest_size_type,
  waist_size_type, 
  hips_size_type,characterId,recruiterId,jobApply}) => {
  return axios.get(`${apiUrl}/v1/talenter/find-talents?page=${page > 1? page :1}&limit=${limit}&currentCity=${location??''}&talentType=${talentType??''}&gender=${gender??''}&minAge=${minAge??''}&maxAge=${maxAge??''}&imdb=${imdb??''}&facebook=${facebook??''}&instagram=${instagram??''}&complexion=${skinColor ?? ''}&hairColor=${hairColor??''}&eyeColor=${eyeColor??''}&ethnicity=${ethnicity??''}&citiesVisited=${citiesVisited ?? ''}&countriesVisited=${countryVisited ?? ''}&validPassport=${passportStatus ?? ''}&accent=${accentSkill ?? ''}&actingEducation=${actingEducation ?? ''}&theatreExperince=${theatreExperince ?? ''}&heighestEducation=${heighestEducation ?? ''}&hobbies=${hobbies ?? ''}&skills=${skills ?? ''}&projectType=${projectType??''}&talentPastwork=${talentPastwork ?? ''}&achievmentDetail=${achievmentDetail ?? ''}&otherNotableWork=${otherNotableWork ?? ''}&role=${roleType??''}&nudity=${nudity??''}&loveMaking=${loveMaking??''}&language=${languageSKill ?? ''}&nationality=${nationality ?? ''}&nativeCity=${nativeCity ?? ''}&talentOpenWorkCity=${talentOpenWorkCity ?? ''}&recruiterViewed=${recruiterViewed ?? ''}&minHeight=${height ?? ''}&maxHeight=${heightMax ?? ''}&heightSizeType=${height_size_type ?? ''}&minWeight=${weight_min_value ?? ''}&maxWeight=${weight_max_value ?? ''}&weightSizeType=${weight_size_type ?? ''}&minChest=${bust_or_chest_min ?? ''}&maxChest=${bust_or_chest_max ?? ''}&chestSizeType=${bust_or_chest_size_type ?? ''}&minWaist=${waist_min ?? ''}&maxWaist=${waist_max ?? ''}&waistSizeType=${waist_size_type ?? ''}&minHip=${hip_min ?? ''}&maxHip=${hip_max ?? ''}&hipSizeType=${hips_size_type ?? ''}&characterId=${characterId}&recruiterId=${recruiterId}&jobApply=${jobApply ?? false}`,).then(resp => resp.data)
}

const SaveFilterByuserId = async (payload) => {
  return axios.post(`${apiUrl}/v1/findTalent-saveSearch`,payload).then(response => {
    return response.data
  })
}


const LoadSavedFilterList = async (userId) => {
  return axios.get(`${apiUrl}/v1/findTalent-saveSearch/${userId}`).then(response => {
    return response.data
  })
}

const GetSavedFilter = async (id) => {
  return axios.get(`${apiUrl}/v1/findTalent-saveSearch/load-search/${id}`).then(response => {
    return response.data
  })
}


const getTalentData = async (talentId,recruiterId='') => {
  return axios.get(`${apiUrl}/v1/recruiter-unlocked/recruiter-view?talentId=${talentId}&recruiterId=${recruiterId}`).then(response => response.data)
}

const HandleUnlockTalent = async (payload) => {
  return axios.post(`${apiUrl}/v1/recruiter-unlocked`,payload).then(response => response.data)
}

const GetgallerybyuserId = async (id) => {
  return axios.get(`${apiUrl}/v1/image-upload/${id}`).then(response => response.data)
}
// character -------------------
const GetCharacterList = async (id) => {
  return axios.get(`${apiUrl}/v1/recruiter-project-character-library/get-all`).then(resp => resp.data)
}
const GetCharacterListByRecruiter_id = async (id) => {
  return axios.get(`${apiUrl}/v1/recruiter-project-character-library/getAll-Character/${id}`).then(resp => resp.data)
}
const AddShortlistbyCharacter = async (payload) => {
  return axios.post(`${apiUrl}/v1/recruiter-character-shortlists`,payload).then(resp => resp.data)
}

const HandleDeleteShortlistbyCharacterId = async (SelectedCharacter) => {
  let data = {
    "charcter_id":[SelectedCharacter]
  }
  return axios.delete(`${apiUrl}/v1/recruiter-character-shortlists/recruiter-shortlist-delete`,{data}).then(resp => resp.data)
}

const HandleRemoveShortlistedTalent =  async (id) => {
  return axios.delete(`${apiUrl}/v1/recruiter-character-shortlists/${id}`).then(resp => resp.data)
}

const GetShortlistData = async (characterId,recruiterId) => {
  return axios.get(`${apiUrl}/v1/recruiter-character-shortlists/recruiter-shortlist?characterId=${characterId ?? ''}&recruiterId=${recruiterId??''}`).then(resp =>resp.data)
}

const handleupdateViewed = (payload) => {
  return axios.post(`${apiUrl}/v1/recruiter-view`,payload).then(resp =>resp.data)
}

const handleShortlistPageData = ({page,limit=10,characterId,recruiterId,isRecruiterUnlocked}) =>{
  return axios.get(`${apiUrl}/v1/recruiter-character-shortlists/shortlist-list?page=${page + 1}&limit=${limit}&characterId=${characterId}&recruiterId=${recruiterId}&isRecruiterUnlocked=${isRecruiterUnlocked}`).then(resp => resp.data)
}

const handleBulkRemoveShortlistedTalent = async (selectedShortlist) => {
  let data = {
    "id":selectedShortlist
  }
  return  axios.delete(`${apiUrl}/v1/recruiter-character-shortlists/bulk/shortlist-delete`,{data}).then(resp => resp.data)
}

const HandleShortListNoteChanges = async (id,payload) => {
  return axios.patch(`${apiUrl}/v1/recruiter-character-shortlists/${id}`,payload).then(resp => resp.data)
}

const GetAllProject = async () => {
  return axios.get(`${apiUrl}/v1/recruiter-project/get-all`).then(resp => resp.data)
}
const GetRecruiterBasedProject = async (recruiter_id) => {
  return axios.get(`${apiUrl}/v1/recruiter-project/recruiter-based-project/${recruiter_id}`).then(resp => resp.data)
}

const getProjectDetailsbyCharacterId = async (id) => {
  return axios.get(`${apiUrl}/v1/recruiter-project-character-library/${id}`).then(resp => resp.data)
}

const getDocsbyProjectId = async (id) => {
  return axios.get(`${apiUrl}/v1/recruiter-project/getdocuments/${id}`).then(resp => resp.data)
}

const BulkShareProjectByTalentId = (payload) =>{
  return axios.post(`${apiUrl}/v1/share-project-details/createshareproject`,payload).then(resp => resp.data)
}

const HandleMakeAnOffer = async (payload) => {
  return axios.post(`${apiUrl}/v1/makeAnOffer`,{offerData:payload}).then(resp => resp.data)
}

const HandleAddDocs = async (payload) => {
  return axios.post(`${apiUrl}/v1/documentupload/createdocument`,payload).then(resp => resp.data)
}

const GetProjectDocsDetails = async (id) => {
  return axios.get(`${apiUrl}/v1/documentupload/${id}`).then(resp => resp.data)
}

const GetCharacterListbyProjectId= async (id) => {
  return axios.get(`${apiUrl}/v1/recruiter-project-character-library/character-list?projectId=${id}`).then(resp => resp.data)
}

const HandleUpdateShareProject = (id,payload) => {
  return axios.patch(`${apiUrl}/v1/share-project-details/${id}`,payload).then(resp => resp.data)
}

const checkAlreadyShared = ({recruiter_id,project_id,character_id,talent_id}) => {
  return axios.get(`${apiUrl}/v1/share-project-details/get-details/recruiter-character-project-Id?projectId=${project_id}&userId=${talent_id}&recruiterId=${recruiter_id}&characterId=${character_id}`).then(resp => resp.data)
}

const checkAlreadyShortlist = ({recruiter_id,character_id,talent_id}) => {
  return axios.get(`${apiUrl}/v1/recruiter-character-shortlists/get-all-details?characterId=${character_id}&userId=${talent_id}&recruiterId=${recruiter_id}`).then(resp => resp.data)
}

export const findTalentService = {
    GetDropdownValues,
    FilterTalentData,
    SaveFilterByuserId,
    LoadSavedFilterList,
    GetSavedFilter,
    getTalentData,
    HandleUnlockTalent,
    GetgallerybyuserId,
    GetCharacterList,
    AddShortlistbyCharacter,
    HandleDeleteShortlistbyCharacterId,
    HandleRemoveShortlistedTalent,
    GetShortlistData,
    handleupdateViewed,
    handleShortlistPageData,
    handleBulkRemoveShortlistedTalent,
    HandleShortListNoteChanges,
    GetAllProject,
    getProjectDetailsbyCharacterId,
    getDocsbyProjectId,
    BulkShareProjectByTalentId,
    HandleMakeAnOffer,
    HandleAddDocs,
    GetProjectDocsDetails,
    GetCharacterListbyProjectId,
    HandleUpdateShareProject,
    GetCharacterListByRecruiter_id,
    GetRecruiterBasedProject,
    checkAlreadyShared,
    checkAlreadyShortlist,
}