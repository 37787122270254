import React from 'react'


import Lottie from 'react-lottie-player'
import loaderJson from '../assets/img/Loader.json'

function ThemedSuspense() {
  return (
    <div className="w-full h-screen p-6 text-lg font-medium bg-white text-gray-600   flex justify-center items-center">

      {/* <img src={loader} alt='loader' /> */}
      <Lottie
        loop
        animationData={loaderJson}
        play
        style={{maxWidth: "600px", maxHeight: "600px" }}
      />

    </div>
  )
}

export default ThemedSuspense
