import axios from 'axios'
import { config } from '../../assets/config/config'


const apiUrl = config.api.url


const createTalenter_PhysicalDescription = (id,user_id , screen_min_age, screen_max_age, eye_color, skin_color, natural_hair_color, bust_or_chest, waist,hips,height,weight,bust_or_chest_size_type,waist_size_type,hips_size_type,height_size_type,weight_size_type) => {
	return axios.post(`${apiUrl}/v1/talenter-physical-description`, {
   id: id,
    user_id: user_id,
    screen_min_age: screen_min_age,
    screen_max_age: screen_max_age,
    eye_color: eye_color,
    skin_color: skin_color,
    natural_hair_color: natural_hair_color,
    bust_or_chest: bust_or_chest,
    waist: waist,
    hips: hips,
    height: height,
    weight: weight,
    bust_or_chest_size_type: bust_or_chest_size_type,
    waist_size_type: waist_size_type,
    hips_size_type: hips_size_type,
    height_size_type: height_size_type,
    weight_size_type: weight_size_type,
  })
}

const getPhysicalDescriptionById = (talent_id) => {
	return axios.get(`${apiUrl}/v1/talenter-physical-description/${talent_id}`, {}).then((response) => {
		return response.data
	})
}

export const physicalDescriptionService = {
    createTalenter_PhysicalDescription,
    getPhysicalDescriptionById
}