import { config } from "../../assets/config/config"
import axios from 'axios'

const apiUrl = config.api.url


const GetDropdownFindJobsValues = async (gender,minAge,maxAge,projectType,location,activeJob) => {
    console.log('-----activeJob--------->',activeJob);
    // const res = await axios.get(`${apiUrl}/v1/auditions/data/get-All?gender_id=${getDataCount??''}&min_age=${minAge??''}&max_age=${maxAge??''}&project_id=${projectType??''}&location_id=${nativeCity??''}`,)
    const res = await axios.get(`${apiUrl}/v1/auditions/count/filterdatacount?gender=${gender??''}&min_age=${minAge??''}&max_age=${maxAge??''}&project=${projectType??''}&location=${location??''}&activeJobs=${activeJob ?? false}`,)
    return res.data
}

const FilterFindJobsData = async ({page=0,limit=10,gender,minAge,maxAge,projectType,nativeCity,user_id,sort_id,activeJob}) =>{
    let userQuery = user_id? `&user_id=${user_id}`:''
    return axios.get(`${apiUrl}/v1/auditions/data/get-All?page=${page + 1}&limit=${limit}&gender_id=${gender??''}&min_age=${minAge??''}&max_age=${maxAge??''}&project_id=${projectType??''}&location_id=${nativeCity??''}${userQuery}&sort_id=${sort_id ?? '1'}&activeJobs=${activeJob ?? false}`).then(data => data.data)
}
const FilterCastingbellOpeningsData = async ({page=0,limit=10,gender,minAge,maxAge,projectType,nativeCity,user_id,sort_id,activeJob}) =>{
    let userQuery = user_id? `&user_id=${user_id}`:''
    return axios.get(`${apiUrl}/v1/auditions/list/castingbell-openings?page=${page + 1}&limit=${limit}&gender_id=${gender??''}&min_age=${minAge??''}&max_age=${maxAge??''}&project_id=${projectType??''}&location_id=${nativeCity??''}${userQuery}&activeJobs=${activeJob ?? false}`).then(data => data.data)
}

const FindJobsList = async() => {
    return axios.get(`${apiUrl}/v1/auditions/data/get-All`).then(response => {
        return response.data
    })
}


export const findJobService ={
    FindJobsList,
    GetDropdownFindJobsValues,
    FilterFindJobsData,
    FilterCastingbellOpeningsData
}