import axios from 'axios'
import { config } from '../../assets/config/config'
const apiUrl = config.api.url

const getJobAuditionByuserId = (id,page=1,limit=10) => {
 return axios.get(`${apiUrl}/v1/job-audition-apply/user?page=${page == 0 ? 1 :page }&limit=${limit}&user_id=${id} `).then((res) => {
  return res
})
}

const FilterMyApplicationsData = async ({page=0,limit=10,projectType,nativeCity,user_id,jobApplyStatus}) =>{
  let userQuery = user_id? `&user_id=${user_id}`:''
  return axios.get(`${apiUrl}/v1/job-audition-apply/user?page=${page + 1 }&limit=${limit}&projectType=${projectType??''}&location=${nativeCity??''}${userQuery}&jobApplyStatus=${jobApplyStatus ?? ''}`).then((res) => {
    return res
  })
}

export const myApplicationService = {
  getJobAuditionByuserId,
  FilterMyApplicationsData
}