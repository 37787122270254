import axios from 'axios'
import { config } from '../assets/config/config'

const apiUrl = config.api.url

const getUsers = (page) => {
	return axios.get(`${apiUrl}/v1/users?limit=${config.users.resultsPerPage}&page=${page}`, {})
		.then(response => {
	    return response.data
	  })
}

const createOauthUser = (user) => {
  return axios.post(`${apiUrl}/v1/talenter`, user)
}


const createUser = (username, email, password, role) => {
	return axios.post(`${apiUrl}/v1/users`, {
    name: username,
    email: email,
    password: password,
    role: role
  })
}
const createSignup = (userData) => {
	return axios.post(`${apiUrl}/v1/user/send-gmail-otp`,userData)
}

const verifyGmailOTP = (userData) => {
	return axios.post(`${apiUrl}/v1/user/verify-gmail-otp`,userData)
  
}

const emailRecruieter = (userData) => {
	return axios.post(`${apiUrl}/v1/recruiter/send-email`,userData)
  
}

const createLeads = (payload) => {
  return axios.post(`${apiUrl}/v1/recruiter_leads`,payload).then((res) =>{return res.data})
}


const updateUserPassword = (userId, password) => {
	return axios.patch(`${apiUrl}/v1/users/${userId}`, {
    password: password
  })
}

const updateUserDetails = (userId, username, email) => {
	return axios.patch(`${apiUrl}/v1/users/${userId}`, {
    name: username,
    email: email
  })
}

const deleteUser = (userId) => {
	return axios.delete(`${apiUrl}/v1/users/${userId}`, {})
}


export const userService = {
  getUsers,
  createUser,
  createLeads,
  verifyGmailOTP,
  emailRecruieter,
  createSignup,
  updateUserPassword,
  updateUserDetails,
  deleteUser,
  createOauthUser
}