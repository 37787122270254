import axios from 'axios'
import { config } from '../../assets/config/config'


const apiUrl = config.api.url



const createTalenter_Prefenence = (talent_id, preferred_role, preferred_projects, preferred_cites, nudity, love_making_scenes) => {
  return axios.post(`${apiUrl}/v1/preference`, {
    talent_id: talent_id,
    preferred_role: preferred_role,
    preferred_projects: preferred_projects,
    preferred_cites: preferred_cites,
    nudity: nudity,
    love_making_scenes: love_making_scenes

  })
}

const getPreferenceById = (talent_id) => {
  return axios.get(`${apiUrl}/v1/preference/${talent_id}`, {}).then((response) => {
    return response.data
  })
}

const getRole = () => {
  return axios.get(`${apiUrl}/v1/roles/get-all`).then((res) => {
    return res
  })
}
const getPreferedProject = () => {
  return axios.get(`${apiUrl}/v1/project_type/get-all`).then((res) => {
    return res
  })
}
const getCity = (anywhere = 1) => {
  return axios.get(`${apiUrl}/v1/city/get-all?anywhere=${anywhere}`).then((res) => {
    return res;
  })
}

const getFilterRoleData = (val) => {
  return axios.post(`${apiUrl}/v1/roles/filter-role`, { role_name: val, }).then((res) => {
    return res;
  })
}

const getFilterProject = (val) => {
  return axios.post(`${apiUrl}/v1/project_type/filter-project_type`, { projectname: val, }).then((res) => {
    return res;
  })
}
const getFilterCities = (val) => {
  return axios.post(`${apiUrl}/v1/city/filter-city`, { city_name: val, }).then((res) => {
    return res;
  })
}

export const preferenceService = {
  getPreferenceById,
  createTalenter_Prefenence,
  getRole,
  getPreferedProject,
  getCity,
  getFilterRoleData,
  getFilterProject,
  getFilterCities

}