import React,{ useRef, useState, useContext } from "react"; //useCallback, useEffect
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import '../../pages/TalenterDashBoard/TalenterDashBoard.css';

import { Avatar } from '@windmill/react-ui';
import { useLocation } from 'react-router';
import { CircularProgress, ListItemIcon } from '@mui/material';
import { AuthContext } from "../../context/AuthContext";



export default function ProfileDropDown({ img, firstName, lastName, handleProfile, handleLogout,handleEditProfileImage,mobileView }) {
 const [anchorEl, setAnchorEl] = React.useState(null);
 const open = Boolean(anchorEl);
 const location = useLocation()
 const { user, refreshEditPage } = useContext(AuthContext); //refreshToken
 const [isLoading, setIsLoading] = React.useState(false)


 const handleClick = (event) => {
  document.body.classList.add('modal-open');
  setAnchorEl(event.currentTarget);
 };
 const handleClose = () => {
  document.body.classList.remove('modal-open');
  setAnchorEl(null);

 };

 return (
  <React.Fragment>
   <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
    {/* <Tooltip title="Account settings"> */}
    <button
     className="profileDropDownButton rounded-full focus:shadow-outline-purple focus:outline-none "
     onClick={handleClick}
     aria-controls={open ? 'account-menu' : undefined}
     aria-haspopup="true"
     aria-expanded={open ? 'true' : undefined}
    >
      <Avatar
      className={mobileView ? "align-middle top_profile_info" : "align-middle top_user_info"}
      src={img}
      alt=""
      aria-hidden="true"
     />
     {/* <Avatar
      className="align-middle top_user_info"
      src={img}
      alt=""
      aria-hidden="true"
     />  */}
     <span className="user_name sm_displayNone">{firstName} </span>
    </button>
   </Box>
   <Menu
    anchorEl={anchorEl}
    id="account-menu"
    open={open}
    onClose={handleClose}
    // onClick={handleClose}
    PaperProps={{
     elevation: 0,
     sx: {
      overflow: 'visible',
      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.5))',
      mt: 1.5,
      '& .MuiAvatar-root': {
       width: 32,
       height: 32,
       ml: -0.5,
       mr: 1,
     
      },

     },
    }}
    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    // disableAutoFocusItem={true}
    className="after_signup_section"
   >
    <MenuItem>
     <div className='px-4 flex mb-1'>
      <div className="left_profile">
       <img style={{width: 50, height: 50, borderRadius: 100, objectFit: 'cover', ...mobileView? {backgroundColor: "none"} : {backgroundColor:'#9b26b6'}  } } src={img} alt="Profile" />
      </div>
      <div className="right_profile_info">
       <h4>{firstName} {lastName}</h4>
       <label>Active</label>
      </div>
     </div>
    </MenuItem>
    {!location?.pathname?.includes('/app/talent/create') ?  <MenuItem onClick={(e) => {handleProfile(e);handleClose(e)}} style={{  minHeight:'fit-content'}}> <p className='profileDropDownItems mx-4'>Profile</p></MenuItem>:null}

   {user?.talenter !== null || user?.recruiter !== null ? <MenuItem onClick={(e) => {
            handleEditProfileImage(e);
            handleClose(e)}}
  style={{  minHeight:'fit-content'}}> <p className='profileDropDownItems mx-4'>Change Profile Icon </p></MenuItem> : null} 
   

    <MenuItem onClick={(e) => {
        setIsLoading(true)
        handleLogout(e).then(resp => handleClose(e)).finally(() => setIsLoading(false))
        }} style={{  minHeight:'fit-content'}} disabled={isLoading}><p className='profileDropDownItems mx-4'>Logout</p></MenuItem>
   </Menu>
  </React.Fragment>
 );
}
