import React, { lazy, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom"; //useParams
// import { MoonIcon, SunIcon } from "../icons";
import { AuthContext } from "../context/AuthContext";
import Pusher from "pusher-js";
import Logo from "../assets/img/Logo.svg";
import UserImg from "../assets/img/personal-data.svg";
import MobileUserImg from "../assets/img/personal-user-data.svg";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { notificationService } from "../services"; //recruitersService
import NotificationDropDown from "./Dropdowns/NotificationDropDown";
import ProfileDropDown from "./Dropdowns/ProfileDropDown";
import Burger from  "../pages/HomePage/CheeseBurgerMenu"
// import WorkTwoToneIcon from "@mui/icons-material/WorkTwoTone";
import { Avatar } from "@mui/material";
import { GlobalContext } from './NameContext';
// import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
// import IconButton from '@mui/material/IconButton';
import EditImagePopup from "../components/EditProfileImage/EditProfileImgDialog";
import Logo_Icon from '../assets/img/logo_icon.svg'
import app_logo from '../assets/img/app_logo.svg'

const Signup = lazy(() => import("../pages/signup/Signup"));
const Signin = lazy(() => import("../pages/signup/signin/signin"));

function Header() {
	const [showMore, setShowMore] = useState(false)
	const { setGlobalValue } = useContext(GlobalContext);
	const handleRefresh = () => {
		setGlobalValue(''); // Empty the globalContent
	  };
	  useEffect(() => {
		handleRefresh()
	})
	const [currentPath, setCurrentPath] = useState("notification");
	let URL = window.location.href;
	useEffect(() => {
		setCurrentPath(URL.split("/").pop())
		setShowMore(false)
	}, [URL])
	const { globalValue } = useContext(GlobalContext);
	
  	const TalentType = 1;
  	const RecruiterType = 2;
	const {
		user,
		logout,
		isSignUpPopup,
		setIsSignUpPopup,
		isSignInPopup,
		setIsSignInPopup,
		updateUser,
		isLoaded,
		isNewNotificationTiggered,
		// setIsNewNotificationTiggered,
		// accessToken,
		refreshTokens,
	} = useContext(AuthContext);
	const history = useHistory();
	const location = useLocation();
	// const [isNotificationsMenuOpen, setIsNotificationsMenuOpen] = useState(false);
	// const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
	const [notificationData, setNotificationData] = useState([]);
	const [isNewNotification, setIsNewNotification] = useState(false);
	const [notificationCount, setNotificationCount] = useState(0);
	const [editProfileImage,setEditProfileImage] = useState(false)
	const path = location.pathname;

	const pageTitle = currentPath === "notification"
                    ? "Notifications"
                    : currentPath === "subscription"
                    ? "My Subscription"
                    : currentPath === "payment"
                    ? "Billing and Payment"
                    : currentPath === "profile"
                    ? "Profile Settings"
                    : currentPath === "contact"
                    ? "Contact us"
					: currentPath === "my-unlocked-profiles"
					? "My Unlocked Profiles"
					: currentPath === "credits-balance"
					? "Credits Balance & History"
					: currentPath === "contact-us"
					? "Contact Us"
					: currentPath === "open-projects" || path?.includes('open-projects')
					? "Open Projects"
					: currentPath === "closed-projects" || path?.includes('closed-projects')
					? "Closed Projects"
					: currentPath === "my-applications"
					? "My Applications"
					: currentPath === "casting-bell-openings"
					? "CB Openings"
					// : currentPath === "" && currentPath === "talents"
                    // ? <div className="weblogo header_left mobilemenuhidden">
					// 	<Link to={"/"} className="logoImage">
					// 		<img src={Logo} alt="CastingBell" />
					// 	</Link>
					// </div> 
                    : globalValue

	useEffect(() => {
		if (user?.user_id) {
		GetNotification(user?.user_id);
		}
	}, [user]);

	useEffect(() => {
		if (isLoaded) {
		if (window.localStorage.getItem('logout')) {

		} else {
			if (document.hasFocus()) {
			if (!user?.user_id) {
				refreshTokens()
			}
			} else {
			if (!user?.user_id) {
				refreshTokens()
			}
			}
		}

		}
	}, [user, isLoaded]);

  useEffect(() => {
	const pusher = new Pusher("67d45544ef59ad205017", {
	  cluster: "ap2",
	});

	const channel = pusher.subscribe("notification");
	channel.bind("talent", function (tigger) {
	  // based on current user
	  if (user?.user_id === parseInt( tigger?.user_id)) {
		GetNotification(user?.user_id);
		notificationService
		  .getNotificationCountByuserID(user?.user_id)
		  .then((resp) => {
			if (resp.data.notificationCount) {
			  setNotificationCount(resp.data.notificationCount);
			} else {
			  setNotificationCount(0);
			}
		  });
		setIsNewNotification(true);
		if (tigger?.userUpdate) {
		  updateUser();
		}
		if (tigger?.logout) {
			console.log('---talent trigger logout---->');
		  logout();
		}
	  }
	});
	channel.bind("recruiter", function (tigger) {
	  // based on current user
	  if (user?.user_id === parseInt(tigger?.user_id) ) {
		GetNotification(user?.user_id);
		notificationService
		  .getNotificationCountByuserID(user?.user_id)
		  .then((resp) => {
			if (resp.data.notificationCount) {
			  setNotificationCount(resp.data.notificationCount);
			} else {
			  setNotificationCount(0);
			}
		  });
		setIsNewNotification(true);
		if (tigger?.userUpdate) {
		  updateUser();
		}
		if (tigger?.logout) {
			console.log('---recruiter trigger logout---->');
		  logout();
		}
	  }
	});

	return () => {
	  channel.unbind_all();
	  channel.unsubscribe();
	};
  }, []);

  const GetNotification = (id) => {
	notificationService.getLastThreeNotificationByid(id).then((resp) => {
	  setNotificationData(resp.data);
	});
  };

  const handleMarkasRead = (id) => {
	notificationService.setReadNotificationById(id).finally(() => {
	  GetNotification(user?.user_id);
	});
  };


  useEffect(() => {
	if (isNewNotificationTiggered) {
	  setIsNewNotification(true);
	}
  }, [isNewNotificationTiggered]);

  const [navbar, setNavbar] = useState(false);
//   const [EditImage, setEditImage] = useState(null);

  return (
	<div className={user ? "header_top_section talenterhomepage":`header_top_section beforetalenterhomepage  ${path === '/' ? 'mainLanding': (path !== '/recruiters' && path !== '/talents') ? 'publicLanding': ''}`}>

	  <nav className="w-full bg-white shadow">
	   <Burger />
	  	<Signin open={isSignInPopup} onClose={setIsSignInPopup} />
		<Signup open={isSignUpPopup} onClose={setIsSignUpPopup} />
		<div className="justify-between px-4 mx-auto container md:items-center md:flex md:px-">
		  <div className="header_left">
			<div className="flex items-center justify-between py-3 md:py-5 md:block">
				<div className="castingbell_logo">
					{user ?
					<div className="md:hidden mobile_view_title flex align-center" >
						
						{pageTitle ? 
							<label class="flex align-center gap-1">
								<Link to={"/"} className="LogomobileView">
									<img src={Logo_Icon} alt="CastingBell"/>
								</Link> 
								{
									(pageTitle && pageTitle.length > 18) ? <span onClick={() => setShowMore(!showMore)} className="lengthText">
									<b >{showMore ? pageTitle?.trim() : `${(pageTitle?.trim())?.substring(0, 18)}...` }</b></span> : <b className="NormalText">{pageTitle?.trim()}</b>
								}
							</label>
						: <Link to={"/"} className="LogomobileView">
								<img src={app_logo} alt="CastingBell" />
							</Link>
						}
					
					</div> 
					: 	globalValue 
					? 
					<div class="md:hidden mobile_view_title flex align-center gap-1">
						<Link to={"/"} className="LogomobileView">
							<img src={Logo_Icon} alt="CastingBell"/>
						</Link> 
						{
							(pageTitle && pageTitle.length > 18) ? <span onClick={() => setShowMore(!showMore)} className="lengthText" style={{}}>
							<b >{showMore ? pageTitle?.trim() : `${(pageTitle?.trim())?.substring(0, 18)}...` }</b></span> : <b className="NormalText">{pageTitle?.trim()}</b>
						}
					</div>
					: <div className="md:hidden mobile_view_title">
							<Link to={"/"} className="LogomobileView">
								<img src={app_logo} alt="CastingBell" />
							</Link>
						</div> 
					}

					<div className="weblogo header_left mobilemenuhidden">
						<Link to={"/"} className="logoImage">
							<img src={Logo} alt="CastingBell" />
						</Link>
					</div> 
					
				</div>
				
				{!showMore && <div className="md:hidden newmobileiconcolor">
					<ul className="flex items-center gap_chips">
						{/* Notifications for both Recruiter / talent  */}

						{user?.user_type === RecruiterType ||
						(user?.user_type === TalentType &&
							user?.talenter?.is_profile_creation_completed) ? (
						<li>
							<NotificationDropDown
							DropDownData={notificationData}
							isNewMenu={isNewNotification}
							setIsNewMenu={setIsNewNotification}
							handleUpdateData={() => GetNotification(user?.user_id)}
							HandleMarkasRead={handleMarkasRead}
							newNotificationCount={notificationCount}
							setNotificationCount={setNotificationCount}
							/>
						</li>
						) : null}

						{user && (
						<li>
							<ProfileDropDown
							img={
								user.user_type === 1 && user?.talenter?.talent_edit_image?	
								user?.talenter?.talent_edit_image?
								user?.talenter?.talent_edit_image:
								user?.gallary?.find((each) => each.is_cover_photo)?.file_url
								? user?.gallary?.find((each) => each.is_cover_photo)
									?.file_url
								: MobileUserImg : user.user_type === 2 && user?.recruiter?.recruiter_edit_image ?
								user?.recruiter?.recruiter_edit_image?  user?.recruiter?.recruiter_edit_image:	user?.recruiter?.recruiter_cover_image ? user?.recruiter?.recruiter_cover_image
								: MobileUserImg :MobileUserImg
							}
							firstName={user?.first_name}
							lastName={user?.last_name}
							handleProfile={() => {
								if (user?.user_type === TalentType) {
								if (
									user?.talenter?.is_profile_creation_completed === true
								) {
									history.push(`/talent/${user?.userUniqueId}`);
								} else {
									history.push("/app/talent/create");
								}
								return;
								}
								if (user?.user_type === RecruiterType) {
								if (user?.userUniqueId) {
									history.push(`/recruiter/${user?.userUniqueId}`);
								}
								}
							}}
							handleLogout={async () => {
								return await logout().finally((resp) => {
								if (user?.user_type === TalentType) {
									history.push("/talents");
									return
								}
								if (user?.user_type === RecruiterType) {
									history.push(`/recruiters`);
									return
								}
								history.push(`/`);

								});
							}}
							handleEditProfileImage= {() =>{

								setEditProfileImage(true)		
							}}
							mobileView={true}
							/>{" "}
						</li>
						)}
					</ul>
				</div>}

				<div className="md:hidden newmobileiconcolor">
					<ul className={path==="/"?"display_left":"withoutpadding"}>
					{/* Recruiter mobile view */}

				{  (!user && isLoaded && path === '/recruiters' ? 
					<>
					<li className="text-gray-600 hover:text-blue-600">
					<Link to="/find-talents" className="headerTags">
						  <span className="hiddenmobile">Find Talents</span>
							<span className="openinmobile"></span>
					</Link>
				  </li>
					</>
				: null )}

					{/* Talenter mobile view */}					
					{ (!user && isLoaded && path === '/talents'  ? 
					<>
					<li
						className="headerText cursor-pointer "
						onClick={() => history.push("/find-jobs")}
					>
						<span className="sm_displayNone">Find Jobs</span>
					     <span className="openinmobile IconChange"></span>
					</li>
					</>
					:null )}

					{/*find Talent mobile view*/}

				{  (!user && isLoaded  ? 
				<>
					<li className="relative user_signuo_button ">
						<button
						className="rounded-full focus:shadow-outline-purple focus:outline-none "
						aria-label="Account"
						aria-haspopup="true"
						onClick={() => {
							setIsSignInPopup(false);
							setIsSignUpPopup(true);
						}}
						>
						<Avatar
							className="align-middle top_user_info"
							src={UserImg}
							alt=""
							aria-hidden="true"
						/>{" "}
						<span className="user_name"> Sign Up </span>
						</button>
					</li>
					</>
	               
					: null )}

			</ul>
					
				</div>

			</div>
		  </div>


		  <div className="header_left mobilemenuhidden">
			<div
			>
			  <ul className="items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0">
				{!user && isLoaded && path === '/' ? (
					<>	
						<li className="text-gray-600 hover:text-blue-600">
							<Link to="/recruiters" target="_blank" className="headerTags">
								I’m Recruiting
							</Link>
						</li>
						<li className="text-gray-600 hover:text-blue-600">
							<Link to="/talents" target="_blank" className="headerTags">I’m the Talent</Link>
						</li>
						<li className="haeder_signin">
						{" "}
						<Link to="#" onClick={() => setIsSignInPopup(true)}>
						Sign in
						</Link>
					</li>
					</>
				) : null}

				{!user && isLoaded && (path === '/recruiters') ? (
			  		<li className="text-gray-600 hover:text-blue-600">
						<Link to="/find-talents" className="headerTags">
				  			Find Talents
						</Link>
			  		</li>
				) : null}
				
				{!user && isLoaded && (path === '/talents') ? (
					<li
						className="headerText cursor-pointer"
						onClick={() => history.push("/find-jobs")}
					>
						<span className="sm_displayNone">Find Jobs</span>
					
					</li>
					) : null}
				
                  {!user && isLoaded&& path !== '/'&& path !== '/recruiters'&& path !== '/talents'? (
					<>	
					<li className="text-gray-600 hover:text-blue-600">
						<Link to="/recruiters" target="_blank" className="headerTags">
							I’m Recruiting
						</Link>
					</li>
					<li className="text-gray-600 hover:text-blue-600">
						<Link to="/talents" target="_blank" className="headerTags">I’m the Talent</Link>
					</li>
					<li className="haeder_signin">
						{" "}
						<Link to="#" onClick={() => setIsSignInPopup(true)}>
						Sign in
						</Link>
					</li>
				</>
					
					) : (
					""
					)}

					{!user && isLoaded ?(
					<li className="relative user_signuo_button ">
						<button
						className="rounded-full focus:shadow-outline-purple focus:outline-none "
						aria-label="Account"
						aria-haspopup="true"
						onClick={() => {
							setIsSignInPopup(false);
							setIsSignUpPopup(true);
						}}
						>
						<Avatar
							className="align-middle top_user_info"
							src={UserImg}
							alt=""
							aria-hidden="true"
						/>{" "}
						<span className="user_name"> Sign Up </span>
						</button>
					</li>
					) : (
					""
					)}

					{/* Talent  Header Tags  --------------------------- */}
					{user?.user_type === TalentType &&
					user?.talenter?.is_profile_creation_completed && (
						<li
						className="headerText cursor-pointer"
						onClick={() => history.push("/app/openings/open-projects")}
						>
						<span className="">My Jobs</span>
						</li>
					)}

					{user?.user_type === TalentType &&
					user?.talenter?.is_profile_creation_completed && (
						<li
						className="headerText cursor-pointer"
						onClick={() => history.push("/find-jobs")}
						>
						<span className="">Find Jobs</span> 
						</li>

					)}

					{/* Recruiter Header Tags  --------------------------- */}
					{user?.user_type === RecruiterType && (
					<li>
						<Link to="/app/my-projects" className="headerTags" onClick={() => {
						window.scrollTo(0, 0)
						}}>
						My Projects
						</Link>
					</li>
					)}
					{user?.user_type === RecruiterType && (
					<li>
						<Link to="/app/my-job-postings" className="headerTags" onClick={() => {
						window.scrollTo(0, 0)
						}}>
						My Job Postings
						</Link>
					</li>
					)}

					{user?.user_type === RecruiterType && (
					<li>
						<Link to="/find-talents" className="headerTags" onClick={() => {
						window.scrollTo(0, 0)
						}}>
						Find Talents
						</Link>
					</li>
					)}

					{/* Notifications for both Recruiter / talent  */}

					{user?.user_type === RecruiterType ||
					(user?.user_type === TalentType &&
						user?.talenter?.is_profile_creation_completed) ? (
					<li className={`md:mt-0 ${navbar ? "hidden" : "block" }`}>
						<NotificationDropDown
						DropDownData={notificationData}
						isNewMenu={isNewNotification}
						setIsNewMenu={setIsNewNotification}
						handleUpdateData={() => GetNotification(user?.user_id)}
						HandleMarkasRead={handleMarkasRead}
						newNotificationCount={notificationCount}
						setNotificationCount={setNotificationCount}
						/>
					</li>
					) : null}

					{/* profile menu */}

					{user && (
					<li className={`md:mt-0 ${navbar ? "hidden" : "block" }`}>
						<ProfileDropDown
						img={
							
							
							user.user_type === 1 && user?.talenter?.talent_edit_image
							?	

							user?.talenter?.talent_edit_image
							 :
							user?.gallary?.find((each) => each.is_cover_photo)?.file_url
							?
							 user?.gallary?.find((each) => each.is_cover_photo)
								?.file_url
							: 
							user.user_type === 2 && user?.recruiter?.recruiter_edit_image ?

							user?.recruiter?.recruiter_edit_image :
							 user?.recruiter?.recruiter_cover_image ? user?.recruiter?.recruiter_cover_image : UserImg
					
							
						}
						firstName={user?.first_name}
						lastName={user?.last_name}
						handleProfile={() => {
							if (user?.user_type === TalentType) {
							if (
								user?.talenter?.is_profile_creation_completed === true
							) {
								history.push(`/talent/${user?.userUniqueId}`);
							} else {
								history.push("/app/talent/create");
							}
							return;
							}
							if (user?.user_type === RecruiterType) {
							if (user?.userUniqueId) {
								history.push(`/recruiter/${user?.userUniqueId}`);
							}
							}
						}}

						handleLogout={async () => {
							return await logout().finally((resp) => {
							if (user?.user_type === TalentType) {
								history.push("/talents");
								return
							}
							if (user?.user_type === RecruiterType) {
								history.push(`/recruiters`);
								return
							}
							history.push(`/`);

							});
							

						}}
						handleEditProfileImage= {() =>{

							setEditProfileImage(true)		
						}}

						/>{" "}

						< EditImagePopup
						
						open = {editProfileImage}

						onClose = {()=>{

							setEditProfileImage(false)
						}}
						
						/>
                 </li>
				)} 
			  </ul>
			</div>
		  </div>
		</div>
	  </nav>
	</div>
  );
}

export default Header;
