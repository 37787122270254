import Button from '@mui/material/Button';
import { CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import './Button.css'

//Type of buttons -> white, gray, deleteBtn, disableBtn(in gray color) 
export const CustomButton = ({ isLoading = false, text = 'Save', disabled = false, white, gray, deleteBtn, disableBtn, noHover,outlinegray, ...props }) => {
  return <Button variant="contained" {...props} id='custom_button' className={props?.className + ` ${white && 'white'} ${gray && 'gray'} ${deleteBtn && 'deleteBtn'} ${disableBtn && 'disableBtn'} ${noHover && 'noHover'} ${isLoading && 'noHover'} ${outlinegray ? 'outlinegray' : ''}`} disabled={disabled || isLoading} >
    <span className={isLoading ? 'displayNone':''}>{text}</span>
    {isLoading && <CircularProgress size={'20px'} sx={{ color: white ? '#9B26B6' : (deleteBtn && isLoading) ? "#FF0000":"#ffffff", position: 'absolute' }} />}
  </Button>
}

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 48,
  height: 26,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(10px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(21px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#9B26B6' : '#9B26B6',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 22,
    height: 22,
    borderRadius: 12,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    opacity: '1 !important',
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(0,0,0,.25)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },

}));
//  common switch 
export const CustomSwitch = (props) => {
  return <AntSwitch color="secondary" inputProps={{ 'aria-label': 'controlled' }} {...props}
  />
}
// common Back Button 
export const CustomBackButton = ({isLoading = false, text = 'Save', disabled = false, ...props}) =>{

  return <Button variant="contained" {...props} id='custom_backbutton' className={props?.className} disabled={disabled || isLoading}>
    <span className={isLoading && 'displayNone'}>{text}</span>
    {isLoading && <CircularProgress size={'20px'} sx={{ color: "#ffffff", position: 'absolute' }} />}
  </Button>
}

export const SubmitBtn_white = ({ loading = false, onClick, text }) => {

  return <div className='btn_container_white'><Button variant="contained" className="" onClick={onClick}>
   {loading ? <CircularProgress size={'20px'} sx={{ color: "#ffffff" }} /> : text}
  </Button></div>
 }
 


export const CustomClearButton = ({isLoading = false, text = 'Clear', disabled = false, ...props}) =>{

  return <Button variant="contained" {...props} id='customClearButton' className={props?.className} disabled={disabled || isLoading}>
    <span className={isLoading ? 'displayNone' : ''}>{text}</span>
    {isLoading && <CircularProgress size={'20px'} sx={{ color: "#ffffff", position: 'absolute' }} />}
  </Button>
 }