import axios from 'axios'
import { config } from '../../assets/config/config'


const apiUrl = config.api.url

const handleOauthUser = (user) => {
    return axios.post(`${apiUrl}/v1/user`, user)
}

const handleUserData = (userData) => {
    return axios.post(`${apiUrl}/v1/user/talenter`, userData)
}

const getUserByid = (user_id) => {
    return axios.get(`${apiUrl}/v1/user/${user_id}`, {}).then((response) => {
        return response.data
    })
}

const getTalenterByid = (id) => {
    return axios.get(`${apiUrl}/v1/user/get-talenter/${id}`, {}).then((response) => {
        return response.data
    })
}
const handleUpdateTalenterdata = (userId, talenterData) => {
    return axios.patch(`${apiUrl}/v1/talenter/handle-talenter/${userId}`, talenterData)
}
const getInsta = (payload) => {
    return axios.post(`${apiUrl}/v1/user/insta-access-token`, payload).then(res => res.data)
}
const getCountrybyId = (id) => {
    return axios.get(`${apiUrl}/v1/city/get-by-country/${id}`)
}
const getCityByid = (id) => {
    return axios.get(`${apiUrl}/v1/city/${id}`, {})
}

const UpdateTalent = (id, talenterData) => {
    return axios.patch(`${apiUrl}/v1/talenter/${id}`, talenterData)
}
const updateRecruiter = (user_id,recruiterData) => {
    return axios.patch(`${apiUrl}/v1/recruiter/${user_id}`, recruiterData)
}

const userProfile =(payload,id) =>{
    return axios.patch(`${apiUrl}/v1/user/${id}`,payload).then((res)=>{return res.data})
}
const deleteProfile =(id) =>{
    return axios.delete(`${apiUrl}/v1/user/${id}`).then((res)=>{return res.data})
}
const createContactus = (userData) =>{
    return axios.post(`${apiUrl}/v1/contactus`,userData).then((res)=>{return res.data})

}
const getContactus = () =>{
    return axios.get(`${apiUrl}/v1/contactus`).then((res)=>{return res.data})

}
const VerifyUserWithNum = (num) =>{
    return axios.post(`${apiUrl}/v1/auth/mobile-login-otp`,{
        "mobile": num
    })

}
const VerifyUserWithNumOtp = (num,otp) =>{
    return axios.post(`${apiUrl}/v1/auth/mobile-login-verify`,{
        "mobile": num,
        "otp" : otp,
        // "user_type" : 1
    })

}

const getRecruiterDetails = (id) => {
    return axios.get(`${apiUrl}/v1/user/without/${id}`)
}
const getGuestUserDetails = (id)=> {
    return axios.get(`${apiUrl}/v1/user/guest/${id}`)
}

const createLog = (payload) =>{
    return axios.post(`${apiUrl}/v1/logs`,payload).then((res)=>{return res.data})

}
const contactUs = (payload) =>{
    return axios.post(`${apiUrl}/v1/company-contactus`,payload).then((res)=>{return res.data})
}

export const userServices = {
    handleOauthUser,
    createLog,
    handleUserData,
    deleteProfile,
    userProfile,
    getContactus,
    getUserByid,
    createContactus,
    getTalenterByid,
    handleUpdateTalenterdata,
    getInsta,
    getCountrybyId,
    getCityByid,
    UpdateTalent,
    VerifyUserWithNum,
    VerifyUserWithNumOtp,
    getRecruiterDetails,
    getGuestUserDetails,
    contactUs,
    updateRecruiter,
}