import axios from 'axios'
import { config } from '../../assets/config/config'

const apiUrl = config.api.url


const openCloseProjectList =(id) =>{
    return axios.get(`${apiUrl}/v1/recruiter-project/activeprojects/${id}`,).then(resp => resp.data)
}

const getDouments = (id) => { 
    return axios.get(`${apiUrl}/v1/documentupload/${id}`,).then(resp => resp.data)   
}

const openProject = (payload) => { 
    return axios.post(`${apiUrl}/v1/recruiter-project/clickopenprojectsbyid`,payload).then(resp => resp.data)
}

const deleteDocument =(id) => {
    return axios.delete(`${apiUrl}/v1/documentupload/deletefiles`, { data: { id: id } }).then((response) => {
    })
}

const closeProject =(id ,payload) =>{
    return axios.patch(`${apiUrl}/v1/recruiter-project/${id}`, payload).then((response) => response.data)
}

const updateProject = (id , payload) =>{
    return axios.patch(`${apiUrl}/v1/recruiter-project/${id}`, payload).then((response) =>  response)
}

const addReason = (payload) =>{
    return  axios.post(`${apiUrl}/v1/report-reason`, payload).then((resp) => { })
}

const addDocument = (payload) =>{
    return  axios.post(`${apiUrl}/v1/documentupload/createdocument`, payload).then((response) => {})
}

const closeProjectReason = () => {
    return axios.get(`${apiUrl}/v1/report-reason/reportProjectList`).then(resp => resp.data)
}

const abuseReport = (payload) => {
    return axios.post(`${apiUrl}/v1/report-abuse`, payload).then(resp => resp.data)
}

const projectAbuseReport = (payload) =>{
    return axios.post(`${apiUrl}/v1/project-abuse-report`, payload).then(resp => resp.data)
}

export const openProjectService = {
    getDouments,
    openProject,
    openCloseProjectList,
    deleteDocument,
    closeProject,
    updateProject,
    addReason,
    addDocument,
    closeProjectReason,
    abuseReport,
    projectAbuseReport
}
