import axios from 'axios'
import { config } from '../../assets/config/config'

const apiUrl = config.api.url


const createTalenter_SkillDescription = (talent_id, education, hobbies_interests, skils, notable_achivements, other_notable_achivements, acting_education, theatre_experience, prior_project_name, onGoingProjects, skill_and_education_id) => {
  return axios.post(`${apiUrl}/v1/skill-and-education`, {
    talent_id: talent_id,
    education: education,
    hobbies_interests: hobbies_interests,
    skils: skils,
    notable_achivements: notable_achivements,
    other_notable_achivements: other_notable_achivements,
    acting_education: acting_education,
    theatre_experience: theatre_experience,
    prior_project_name: prior_project_name,
    onGoingProjects: onGoingProjects,
    skill_and_education_id: skill_and_education_id
  })
}


const getSkillAndEducationById = (talent_id) => {
  return axios.get(`${apiUrl}/v1/skill-and-education/${talent_id}`, {}).then((response) => {
    return response.data
  })
}
const getHobbies = () => {
  return axios.get(`${apiUrl}/v1/hobbies/get-all`).then((res) => { return res.data })
}
const getProject = () => {
  return axios.get(`${apiUrl}/v1/project_type/get-all`).then((res) => { return res.data })
}
const getPastWorkProject = () => {
  return axios.get(`${apiUrl}/v1/project-type-new/get-all`).then((res) => { return res.data })
}
const getSkills = () => {
  return axios.get(`${apiUrl}/v1/skills/get-all`).then((res) => { return res.data })
}



const deleteOngoingProject = (project_id) => {
  return axios.delete(`${apiUrl}/v1/skill-and-education/delete-on-going-project/${project_id}`, {}).then((response) => {
    return response.data
  })
}

const getFilterSkills = (val) => {
  return axios.post(`${apiUrl}/v1/skills/filter-skill`, { skill_name: val }).then(res => res)
}


const getFilterHobby = (val) => {
  return axios.post(`${apiUrl}/v1/hobbies/filter-hobbies`, { hobby_name: val }).then(res => res)
}

const addPastWork = (payload) => {
  return axios.post(`${apiUrl}/v1/talenter-past-work`, payload).then((res) => { return res.data })
}
const updatePastwork = (payload,id) => {
  return axios.patch(`${apiUrl}/v1/talenter-past-work/${id}`, payload).then((res) => { return res.data })

}
const deletePastwork =  (id) => {
  const data = {
    id: id
    }
  return axios.delete(`${apiUrl}/v1/talenter-past-work/delete`, {data}).then((res) => { return res.data })

}
export const skillAndEducationService = {
  createTalenter_SkillDescription,
  getSkillAndEducationById,
  deleteOngoingProject,
  getHobbies,
  getProject,
  getSkills,
  addPastWork,
  getFilterSkills,
  getFilterHobby,
  getPastWorkProject,
  updatePastwork,
  deletePastwork
}