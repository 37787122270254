import axios from 'axios'
import { config } from '../../assets/config/config'

const apiUrl = config.api.url

const getUnlockedDetails  = ({recruiter_id,limit=5,page=0,sort}) => {
    return axios.get(`${apiUrl}/v1/recruiter-unlocked/unlock_talents?limit=${limit}&page=${page + 1}&recruiter_id=${recruiter_id}&sort=${sort}`).then(resp => resp.data)
}


export const UnlockService = {
    getUnlockedDetails
}