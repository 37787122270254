import axios from 'axios'
import { config } from '../../assets/config/config'

const apiUrl = config.api.url


const getMyProjectDetail = (recruiter_id) => {
    return axios.get(`${apiUrl}/v1/recruiter-project/openauditions/${recruiter_id}`).then(resp => resp.data)
}

const getCloseProjectDetail = (recruiter_id) => {
    return axios.get(`${apiUrl}/v1/recruiter-project/close-auditions-details/${recruiter_id}`).then(resp => resp.data)
}


const getAuditionDetails  = ({recruiter_id,project_id,character_id,limit=5,page=0,isDecline,sort}) => {
    return axios.get(`${apiUrl}/v1/recruiter-project/selectprojectsbyid?limit=${limit}&page=${page + 1}&recruiter_id=${recruiter_id}&project_id=${project_id ?? ''}&character_id=${character_id ?? ''}&isDecline=${isDecline == true ? true :''}&sort=${sort ?? ''}`).then(resp => resp.data)
}

const getCloseAuditionDetails  = ({recruiter_id,project_id,character_id,limit=5,page=0}) => {
    return axios.get(`${apiUrl}/v1/recruiter-project/recruiter-close-audition?limit=${limit}&page=${page + 1}&recruiter_id=${recruiter_id}&project_id=${project_id ?? ''}&character_id=${character_id ?? ''}`).then(resp => resp.data)
}

const getfilterOptionCount = ({recruiter_id,project_id,character_id}) => {
    return axios.get(`${apiUrl}/v1/recruiter-project/applicant-decline-count/${recruiter_id}/${project_id}/${character_id}`).then(resp => resp.data)
}

const updateAudition = (id, payload) => {
	return axios.patch(`${apiUrl}/v1/auditions/${id}`, payload).then((res) =>  res.data)
}

const createLogs =(payload) =>{
    return axios.post(`${apiUrl}/v1/logs`,payload).then((res)=> res.data )
}

const reportProjectList = () => {
    return axios.get(`${apiUrl}/v1/report-reason/reportProjectList`).then(resp => resp.data)
}

const getMenuOpenOrClose = (characterId) => {
    return axios.get(`${apiUrl}/v1/auditions/audition-character/${characterId}`).then(resp => resp.data)
}

const projectAbuseReport = (payload) =>{
    return axios.post(`${apiUrl}/v1/job-abuse-report`, payload).then(resp => resp.data)
}

export const OpenAuditionService = {
    getMyProjectDetail,
    getAuditionDetails,
    getfilterOptionCount,
    updateAudition,
    createLogs,
    getCloseAuditionDetails,
    getCloseProjectDetail,
    reportProjectList,
    projectAbuseReport,
    getMenuOpenOrClose
}