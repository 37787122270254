import axios from 'axios'
import { config } from '../../assets/config/config'
const apiUrl = config.api.url

const GetTalentProjectDetails = (talent_id,project_id,projectStatus,characterId) => {
    let projectIdQuery = project_id ? `&projectId=${project_id}` : '';
    let projecStatus= projectStatus ? `&projectStatus=${projectStatus}` :'';
    let characterStatus = characterId && characterId != 'undefined' ? `&characterId=${characterId}`:'';
    console.log(characterId != 'undefined',characterId,'characterId')
    return axios.get(`${apiUrl}/v1/share-project-details?userId=${talent_id}${projectIdQuery}${projecStatus}${characterStatus}`).then(resp => resp.data)
}

const GetProjectDetailsById = (talent_id) => {
    return axios.get(`${apiUrl}/v1/share-project-details/project-list?userId=${talent_id}`).then(resp => resp.data)
}

const AcceptOffer = (MarkAnOffer_id,payload) => {
    return axios.patch(`${apiUrl}/v1/makeAnOffer/${MarkAnOffer_id}`,payload).then(resp => resp.data)
}

const DeclinedOffer = (MarkAnOffer_id,payload) => {
    return axios.patch(`${apiUrl}/v1/makeAnOffer/${MarkAnOffer_id}`,payload).then(resp => resp.data)
}

const AddAuditionlink = (payload) => {
    return axios.post(`${apiUrl}/v1/project-audition-link`,payload).then(resp => resp.data)
}

const MarkAsProjectRead = (id) => {
    return axios.patch(`${apiUrl}/v1/share-project-details/${id}`,{isProjectRead:true}).then(resp => resp.data)
}
const removeAuditionInShareProject = (audition_id) => {
    return axios.delete(`${apiUrl}/v1/project-audition-link/${audition_id}`).then(resp =>resp.data)
}
const createLog = (payload) =>{
    return axios.post(`${apiUrl}/v1/logs`,payload).then((res)=>{return res.data})

}
const updateAudition = (id, payload) => {
    return axios.patch(`${apiUrl}/v1/auditions/${id}`, payload).then((res) => { return res.data })
}
const getAllNotificationCount = (user_id) =>{
    return axios.get(`${apiUrl}/v1/notification/getJobNotification-details/${user_id}`).then((res) => { return res.data })

}

const updateNotification = (id,payload) =>{
    return axios.patch(`${apiUrl}/v1/notification/update-notification/${id}`,payload).then((res) => { return res.data })

}
export const TalentProjectService = {
    GetTalentProjectDetails,
    GetProjectDetailsById,
    AcceptOffer,
    DeclinedOffer,
    AddAuditionlink,
    MarkAsProjectRead,
    removeAuditionInShareProject,
    createLog,
    updateAudition,
    getAllNotificationCount,
    updateNotification
  
    
}