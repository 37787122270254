import React, { lazy } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import Footer from "./components/Footer";
import Header from "./components/Header";
import ProtectedRoute from "./components/Routes/ProtectedRoute";
import ForwardRoute from "./components/Routes/ForwardRoute";
import FreezePopup from "./pages/AdminProfile/FreezePopup";
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import { GlobalContextProvider } from './components/NameContext';

const Layout = lazy(() => import("./containers/Layout"));
const Auth = lazy(() => import("./containers/Auth"));
const Landing = lazy(() => import("./pages/Landing"));

const HowitWorks = lazy(() => import('./pages/HowitWorks'))
const Support = lazy(() => import('./pages/Support'))
const Contact = lazy(() => import('./pages/Contact'))
const About = lazy(() => import('./pages/About'))
const SignUp = lazy(() => import('./pages/signup'))
const Dashboard = lazy(() => import('./pages/Dashboard'))
const EmailVerification = lazy(() => import('./pages/EmailVerification'))
const CMSPolicy = lazy(() => import('./containers/CMSPolicy'))
const AuditionPage = lazy(() => import('../src/pages/AuditionOpening/singleAuditionPage'))
const AboutPage = lazy(() => import('./containers/About'))
const UserProfile = lazy (()=>import('./containers/UserProfile'))
const PageError = lazy(() => import('./pages/Error'))
const FindAllJob = lazy(() => import('../src/pages/AuditionOpening/FindAllJob'))
const HomePage = lazy(() => import('../src/pages/HomePage/HomePage'))

// Recruiter -->  imports
const FindTalent = lazy(() =>
  import("./pages/Recruiter/FindTalent/FindTalent")
);
const TalentProfile = lazy(() =>
  import("./pages/Recruiter/TalentProfile/TalentProfile")
);

const FindJobFilter = lazy(() => import('./pages/Jobs/FindJobs'))

const RecruiterLandingPage = lazy(() =>
  import("./pages/Recruiter/RecruiterLandingPage/RecruiterLandingPage")
);
const ImageMagicianProfile = lazy(() => import('./pages/ImageMagician/ImageMagicianProfile'))
const TalentProfileContainer = lazy (()=>import('./containers/TalentProfileContainer'))
const RecruiterProfileContainer = lazy (()=>import('./containers/RecruiterProfileContainer'))

const theme = createTheme({
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 10,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'transparent', // Change this to your desired hover background color
          },
        },
      },
    }
  },
  typography: {
    fontFamily:'Poppins',
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalContextProvider>
        <Router>
          <Header />
          <AccessibleNavigationAnnouncer />
          <div  className='layout_min_height'>
          <Switch>
            <ForwardRoute path="/auth" component={Auth} />
            <Route exact path="/view-cms/:id" component={CMSPolicy} />
            <Route exact path="/about/:id" component={AboutPage} />
            <ForwardRoute path="/dashboard" component={Dashboard} />
            {/* Place new routes over this */}
            <ProtectedRoute path="/app" component={Layout} />
            <Route exact path="/verify-email" component={EmailVerification} />
            {/* <Route exact path="/" component={Auth} /> */}
            <Route exact path="/talents" component={Landing} />
            <Route exact path="/Contact" component={Contact} />
            <Route exact path="/Support" component={Support} />
            <Route exact path="/HowitWorks" component={HowitWorks} />
            <Route exact path="/About" component={About} />
            <Route exact path="/SignUp" component={SignUp} />
            <Route exact path="/jobs/:id" component={AuditionPage} />
            {/* <Route exact path="/find-jobs" component={FindAllJob} /> */}
            <Route exact path="/talent/:userId" component={TalentProfileContainer} />
            <Route exact path={'/'} component={HomePage} />

            {/* ------------------------- Recruiter ------------------- */}
            <Route exact path="/recruiter/:userUniqueId" component={RecruiterProfileContainer} />
            <Route exact path="/recruiters" component={RecruiterLandingPage} />
            <Route exact path="/find-talents" component={FindTalent} />
            {/* <Route exact path="/talent/:talentId" component={TalentProfile} /> */}
            <Route exact path="/find-jobs" component={FindJobFilter} />
            <Route exact path="/find-jobs/:searchParam" component={FindJobFilter} />
            
            {/* ---------------------------Image Magician---------------------------- */}
            <Route exact path="/image-magician" component={ImageMagicianProfile} />
            {/* ---------------------------------------------------------- */}

            <Route exact path="/404" component={PageError} />

            {/* <Redirect exact from='/' to='/talents' /> */}
            <Redirect from="*" to="/" />
            <Redirect from="/landing" to="/" />
          </Switch>
          </div>
          <Footer />
          <FreezePopup />
        </Router>
      </GlobalContextProvider>
    </ThemeProvider>
  );
}

export default App;
