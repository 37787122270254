import axios from 'axios'
import { config } from '../../assets/config/config'

const apiUrl = config.api.url


const getTemplatesByReference = (ref) => {
    return axios.get(`${apiUrl}/v1/notification-templates/by-ref/${ref}`, {})
      .then(response => {
        return response.data
      })
  }
  const getEmailTemplateByID = (id) => {
    return axios.get(`${apiUrl}/v1/notification-templates/${id}`, {})
      .then(response => {
        return response.data
      })
  }
  

export const notificationTempService = {
    getTemplatesByReference,
    getEmailTemplateByID
}