import React, { useState, useMemo } from 'react'



// create context
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

export const SnackbarContext = React.createContext()


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const SnackbarProvider = ({ children,classes }) => {
  const [message, setMessage] = useState('Loading..');
  const [severity, setseverity] = useState(null);
  const [type, setType] = useState('neutral')
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
  const [open, setOpen] = React.useState(false);
  const styles = {
    snackbarStyleViaContentProps: {
      backgroundColor: "#9B26B6"
    },
   
  };

  const closeAlert = () => {
    setOpen(false);
  };
  const value = useMemo(
    () => {
      let timeoutRef = null


      const openSnackbar = (message, type) => {
        setMessage(message)
        setseverity(type)
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
        }, 5000);
      };

      const closeSnackbar = (event, reason) => {
        setOpen(false);
      };

      return ({
        openSnackbar,
        closeSnackbar,
      })
    },
    []
  )

  return (
    <SnackbarContext.Provider value={value}>
      <Snackbar open={open} autoHideDuration={4000}
      // ContentProps={{
      //   "aria-describedby": "message-id",
      //   className: classes.snackbarStyleViaContentProps
      // }}
        onClose={closeAlert} anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        
        >
        <Alert onClose={closeAlert} severity={severity ? severity : 'success'} sx={{ width: '100%' }}>
          {message}!
        </Alert>
      </Snackbar>
      {children}
    </SnackbarContext.Provider>
  )
}
