import axios from 'axios'
import { config } from '../../assets/config/config'

const apiUrl = config.api.url

const getRecruiterBalanceHistoryList = ({recruiterId,startDate,endDate,changeType,page=0,limit=10}) => {
	return axios.get(`${apiUrl}/v1/recruiter-balance-history/balance-history/list?page=${page+1}&limit=${limit}&recruiterId=${recruiterId}&startDate=${startDate}&endDate=${endDate}&changeType=${changeType}`, {})
		.then(response => {
	    return response.data
	  })
}

const getRecruiterBalanceCount = (recruiterId) =>{
    return axios.get(`${apiUrl}/v1/recruiter-balance-history/balance-unlocked-count?recruiterId=${recruiterId}`,{})
    .then(res =>{
        return res.data
    })
}

export const recruiterCreditBalanceService = {
    getRecruiterBalanceCount,
    getRecruiterBalanceHistoryList
}