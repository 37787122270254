import {
  Dialog,
  // DialogActions,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { useRef, useState, useContext } from "react"; //useCallback, useEffect
// import CropperModal from './ProfileEditImage'
// import { useHistory, useLocation } from "react-router-dom";
import { Box, Slider, Button } from "@mui/material"; //Modal
import AvatarEditor from "react-avatar-editor";
import { styled } from "@mui/material/styles";
import {CustomButton } from "../Button/Button"; //CustomBackButton
import "../../pages/Recruiter/ShortList/ShortList.css";
import { AuthContext } from "../../context/AuthContext";
import { recruitersService } from "../../services";
import { s3config } from "../../assets/config/config";
import S3 from "react-aws-s3";
import { Row, Col } from "react-grid-system";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
// import UserImg from "../../assets/img/personal-data.svg";
import { Avatar } from "@mui/material";
import { convertImagetoWebp } from '../../utils/common';
// import userIcon from "../../assets/img/user-avatar.png";
import userIcon from "../../assets/img/UserImgAvatar.svg";

const boxStyle = {
  width: "400px",
  height: "400px",
  display: "flex",
  flexFlow: "column",
  justifyContent: "center",
  alignItems: "center",
};

const CropperModal = ({ src, modalOpen, setModalOpen,setLoading}) => {
  const [ReactS3Client, setReactS3Client] = useState(
    new S3({ ...s3config, dirName: `Recruiter` })
  );

  const { user, refreshEditPage } = useContext(AuthContext); //refreshToken
  const [slideValue, setSlideValue] = useState(10);
  const cropRef = useRef(null);
  const [isFile, setIsFile] = useState(null);
  const [spinner, setSpinner] = useState(false);

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  //handle save
  const handleSave = async () => {
    setSpinner(true);
    setLoading(true)
    if (cropRef) {
      const dataUrl = cropRef.current.getImage().toDataURL();
      let file = dataURLtoFile(dataUrl, cropRef.current.props.image.name);

      let fileName = file.name.replace(/\.[^/.]+$/, '');
      convertImagetoWebp(file).then(async (resp) => fetch(resp).then(r => r.blob()).then(blobFile => new File([blobFile], `${fileName}.webp`, { type: "image/webp" })) ).then(resp => {
        ReactS3Client.uploadFile(resp, resp.name).then((data) => {
          setIsFile(data?.location);
          // setPreview(data?.location);

          if (user?.user_type === 2) {
            var payload = {
              recruiter_edit_image: data?.location,
              recruiter_edit_doc: file.name,
            };

            recruitersService
              .updateRecuitersImgaes(user?.user_id, payload)
              .then((res) => {
                refreshEditPage(user?.user_id);
              })
              .finally(() => {
                setSpinner(false);
                setModalOpen(false);
                setLoading(false)
              });
          } else {
            setSpinner(true);

            var talentPayload = {
              talent_edit_image: data?.location,
              talent_edit_doc: file.name,
            };
            recruitersService
              .updateTalentImgaes(user?.user_id, talentPayload)
              .then((res) => {
                refreshEditPage(user?.user_id);
              })
              .finally(() => {
                setSpinner(false);
                setModalOpen(false);
                setLoading(false)
              });
          }

          return data?.location;
        });
      })
    }
  };

  const HandleCancel = () =>{
    setModalOpen(false)
    setLoading(false)
   }

  return (
    <Dialog
      className="UPprofileIMG"
      open={modalOpen}
      fullWidth={true}
      maxWidth={"sm"}
      id="ShareProjectPopupdata"
    >
      <DialogContent className="Profilechangebutton" align="center">
        <Box className="reducethepopupBox" sx={boxStyle}>
          <AvatarEditor
            ref={cropRef}
            image={src}
            style={{ width: "100%", height: "100%" }}
            border={50}
            borderRadius={150}
            color={[0, 0, 0, 0.72]}
            scale={slideValue / 10}
            rotate={0}
          />

          {/* MUI Slider */}
          <Slider
            className="Changethesliecolor"
            min={10}
            max={50}
            sx={{
              margin: "0 auto",
              width: "80%",
              color: "cyan",
            }}
            size="medium"
            defaultValue={slideValue}
            value={slideValue}
            onChange={(e) => setSlideValue(e.target.value)}
          />
          <Box
            className="RemoveBG"
            sx={{
              display: "flex",
              padding: "10px",
              border: "3px solid white",
              background: "black",
            }}
          >
            <Button
              id="custom_button"
              className="sec_button"
              size="small"
              sx={{ marginRight: "10px", color: "white", borderColor: "white" }}
              variant="outlined"
              onClick={HandleCancel}
            >
              Cancel
            </Button>

            <CustomButton
              text={"Save"}
              onClick={handleSave}
              type="submit"
              isLoading={spinner}
            />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const EditImagePopup = ({ open, onClose }) => {
  const [ReactS3Client, setReactS3Client] = useState(
    new S3({ ...s3config, dirName: `Recruiter` })
  );

  const { user } = useContext(AuthContext);
  // image src
  const [src, setSrc] = useState(null);

  // preview
  // const [preview, setPreview] = useState(null);
  const [imgErr, setimgErr] = useState(false);
  // const [iDProofImgLoading, setIDProofImgLoading] = useState(false);
  const [Loading, setLoading] = useState(false);
  // modal state
  const [modalOpen, setModalOpen] = useState(false);

  const uploadProof = (file) => {
    const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, '');
    convertImagetoWebp(file).then(async (resp) => fetch(resp).then(r => r.blob()).then(blobFile => new File([blobFile], `${fileNameWithoutExtension}.webp`, { type: "image/webp" })) ).then(resp => {
      ReactS3Client.uploadFile(resp, resp.name)
        .then((data) => {
          setModalOpen(true);
          return data?.location;
        })
        .catch((err) => {
        });
    })
  };
  function checkValidFile(inputID, exts) {
    if (
      new RegExp("(" + exts.join("|").replace(/\./g, "\\.") + ")$").test(
        inputID.name?.toLowerCase()
      )
    ) {
      return true;
    } else {
      setimgErr("Please select valid file.");
    }
  }
  const Input = styled("input")({
    display: "none",
  });

  const HandleClose = () =>{
    onClose()
    setimgErr(false)
  }

  return (
    <Dialog
      className="UserprofileImage"
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
      id="ShareProjectPopup" // onClose={open}
    >
      <DialogTitle align="center">
        <h2 className=" shareProject_title mt-7">Change Profile Icon</h2>
      </DialogTitle>
      <DialogContent align="center">
        <>
          <b className="flex">Click to select image</b>
          <main className={{ align: "center" }}>
            <CropperModal
              modalOpen={modalOpen}
              src={src}
              setModalOpen={setModalOpen}
              setLoading ={setLoading}
            />
            <Row>
              <Col sm={9} md={7} lg={7} xl={8}>
                {/* <div className="input_textColor"> */}

                <TextField
                  className="widthfullwidth"
                  id="standard-basic"
                  variant="standard"
                  name={
                    user.user_type === 1
                      ? "talent_edit_doc"
                      : "recruiter_edit_doc"
                  }
                  inputProps={{
                    autocomplete: "new-password",
                    form: { autocomplete: "off" },
                  }}
                  value={
                    user.user_type === 1
                      ? user?.talenter?.talent_edit_doc
                      : user?.recruiter?.recruiter_edit_doc
                  }
                />
                {/* </div> */}

                {imgErr ? <p className="form_err">{imgErr}</p> : null}
              </Col>
              <Col sm={3} md={5} lg={5} xl={4} className="">
                <div className="uplode_file" style={{ margin: 0 }}>
                  <label htmlFor="contained-button-file-data">
                    <Input
                      accept="image/*"
                      id="contained-button-file-data"
                      multiple
                      type="file"
                      onChange={(event) => {
                        if (event.currentTarget.files[0].size <= 10485760) {
                          if (
                            checkValidFile(event.currentTarget.files[0], [
                              ".jpeg",
                              ".jpg",
                              ".png",
                            ])
                          ) {
                            setLoading(true);

                            setimgErr(false);
                            uploadProof(event.currentTarget.files[0]);
                            setSrc(event.currentTarget.files[0]);
                            // setIsUpload(event.currentTarget.files[0].name);
                          }
                        } else {
                          setimgErr("Please select file within 10MB");
                        }
                      }}
                    />
                    <CustomButton
                      text={
                        <div className="flex">
                          <span className="uplode_icon"></span>
                          {"Upload"}
                        </div>
                      }
                      white
                      // onClick ={LoadedData}
                      isLoading={Loading}
                      component="span"
                    />
                  </label>
                </div>
              </Col>
            </Row>
            <>
              <div className="img-circle ">
              
                <img
                  style={{ borderRadius: "125px", height:"200px" }}
                  src={
                    user.user_type === 1 && user?.talenter?.talent_edit_image
                      ? user?.talenter?.talent_edit_image
                      : user?.gallary?.find((each) => each.is_cover_photo)
                          ?.file_url
                      ? user?.gallary?.find((each) => each.is_cover_photo)
                          ?.file_url
                      : user.user_type === 2 &&
                        user?.recruiter?.recruiter_edit_image
                      ? user?.recruiter?.recruiter_edit_image
                      : user?.recruiter?.recruiter_cover_image
                      ? user?.recruiter?.recruiter_cover_image
                      :userIcon
                  }
                  alt=""
                  width={"200"}
                  height={"200"}
                />
              </div>
            </>
          </main>
        </>
      </DialogContent>
      <span className="phot_close__icon" onClick={HandleClose}>
        <CloseIcon />
      </span>
    </Dialog>
  );
};

export default EditImagePopup;
